@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling of the entire page */
}

html, body, #root {
  height: 100%;
}


/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure the html and body elements cover the full viewport */
html, body {
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevent scrolling */
  background-color: #171717; /* Black background */
  color: white; /* Default text color */
}

/* Ensure #root spans the full viewport */
#root {
  height: 100%;
  width: 100%;
}

/* Apply the Satoshi font only to the Login heading */
.login-heading {
  font-family: 'Satoshi', sans-serif;
  font-weight: 700; /* Adjust the weight as needed */
  font-size: 2rem;
  letter-spacing: 0.1rem;
}

.message {
  word-wrap: break-word; /* Allows long words to break and wrap to the next line */
  white-space: normal;   /* Ensures that spaces and line breaks are respected */
  max-width: 100%;       /* Prevents the message from overflowing */
}
@keyframes typing {
  0%, 20% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.animate-typing {
  animation: typing 1.2s infinite;
}

.navbar {
  position: sticky; /* or fixed */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 80px; /* Ensure this matches your layout */
  background: #171717;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
.chat-container {
  flex: 1;
  overflow-y: auto;
  /* max-height: calc(100vh - 100px); */
}
.triangle-container {
  position: fixed;
  padding: 40px; /* Remove padding */
  margin: 40px; 
}
.react-icon {
  /* position: absolute; */
  /* bottom: 77px; */
  /* right: -10px;s */
  display: flex;
  /* background: red; */
}

/* loader */
.loader {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



@keyframes slideIn {
  from {
    transform: translateX(-100%); /* Start off-screen */
  }
  to {
    transform: translateX(0); /* End in place */
  }
}

.slide-panel {
  position: fixed;
  top: 80px; /* Adjusted to your design */
  left: 0;
  padding: 4px;
  margin-left: 8px;
  width: 16rem; /* 64px x 4 = 16rem */
  height: calc(100vh - 80px); /* Full height minus header height */
  background-color: #171717;
  overflow-y: auto;
  transform: translateX(-100%);
  animation: slideIn 0.15s ease-in-out forwards;/* Change the duration for speed control */
  z-index: 10;
  /* background: gray; */
}

.analyze-Content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.custom-toast {
  height: 150px !important;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row; /* Layout: Icon in one column, text in the next */
  align-items: center; /* Vertically center the container */
  text-align: left;
  padding: 10px 20px;
  position: relative;
  gap: 15px; /* Space between the icon and the text */
}

.custom-toast .Toastify__toast-body {
  display: flex;
  flex-direction: row; /* Icon and text side by side */
  align-items: flex-start; /* Align text to the top */
  text-align: left;
  font-family:sans-serif;
  font-size: 0.9rem;
}

.custom-toast .Toastify__toast-body .Toastify__toast-icon {
  display: flex;
  position: relative;
  align-items: center; 
  align-self: center;
  justify-content: center; /* Horizontally center icon */
  width: 30px; /* Icon column width */
}

.custom-toast .Toastify__toast-body .text-container {
  display: flex;
  flex-direction: column; /* Stack text vertically */
  justify-content: flex-start; /* Align text to the top */
  text-align: left; /* Ensure text stays left-aligned */
}

.custom-toast .Toastify__close-button {
  position: absolute; /* Absolute positioning for the button */
  top: 10px;
  right: 10px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}












